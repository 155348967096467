<template>
  <div class="w_auto">
    <div style="padding: 10px; text-align: center">
      {{roomName }}
      <div style="font-size: 12px; color: red">
        温馨提示：如不能观看或聊天，请刷新当前页面！
      </div>
    </div>
    <div class="myplayer">
      <div class="player-div">
        <div class="prism-player" id="player-con"></div>
      </div>
      <div class="player-chat">
        <div class="player-chat-content" @scroll="onScroll">
          <div class="card" v-for="(item, index) in chatList" :key="index">
            <span class="css_user_name">
              {{
                item.memberName != null
                  ? item.memberName
                  : item.userName == null
                  ? "游客"
                  : item.userName
              }}：
            </span>
            <span class="css_content">
              {{ item.chatContent }}
            </span>
          </div>
          <div class="card" id="bottom">
            <span class="css_user_name"> </span>
            <span class="css_content"> </span>
          </div>
        </div>
        <!-- <div style="flex: 1; display: flex; flex-direction: row; align-items: center;margin:10px; background: #464749; height: 40px; line-height: 40px;border: 1px solid #343536;border-radius: 20px;">
        <el-input
            placeholder="请输入内容"
            v-model="message"
            @keyup.enter.native="sendMessage"
            clearable>
        </el-input>
        <el-button  @click="sendMessage" style="color: white;background: #58595B;border-radius: 0 20px 20px 0; border: none;">发送</el-button>
      </div> -->
        <div class="css_ssss">
          <el-input
            placeholder="和大家聊聊吧！"
            v-model="chatContent"
            @keyup.enter.native="sendMessage"
            clearable
          >
          </el-input>
          <el-button type="primary" @click="sendMessage"
            >发送</el-button
          >
        </div>
        
      </div>
    </div>
    <!-- <login v-model="loginDialog" ref="empty"/> -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div style="text-align: center">直播已结束，谢谢观看！</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "axios";
export default {
  // components:{ login },
  data() {
    return {
      liveList: [],
      chatContent: "",
      playerLive: null,
      chatList: [],
      dialogVisible: false,
      isLogin: false,
      isJoin: false,
      wsTimer: null,
      checkLoginTimer: null,
      timer: null,
      ws: null,
      isChat:true,
      userId: "",
      roomName:'',
      room: {
        "roomName": "",
      },
      token: localStorage.getItem("pc_token")||"",
      domainName:''
    };
  },
  mounted() {
    this.initLiveRoomInfo();
    this.domainName = window.location.hostname
    localStorage.setItem('domainName',window.location.hostname)
    this.getAppByDomainNameApi();
  },
  destroyed: function () {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.checkLoginTimer != null) {
      clearInterval(this.checkLoginTimer);
      this.checkLoginTimer = null;
    }
    if (this.wsTimer != null) {
      clearInterval(this.wsTimer);
      this.wsTimer = null;
    }

    if (this.isJoin) {
      this.outLiveJoinPerson();
    }
  },
  methods: {
     //  域名接口
    getAppByDomainNameApi(){
      // this.$api.getAppByDomainNameApi({
      //   domainName:this.domainName
      //   // domainName:'sny.acoer.cn'
      // }).then(res => {
      //   if(res.code == 10000){
      //     localStorage.setItem('channelId',res.data.channelId)
      //     window.VUE_APP_TITLE = res.data.channelName
      //   }
      // })
      localStorage.setItem('channelId',"NJGS")
    },
    initLiveRoomInfo() {
      console.log(this.$route.query.id)
      let that =this
      this.$api
        .queryLiveRoomDetailApi({
          liveRoomId: this.$route.query.id,
        })
        .then((res) => {
          if (res.code == 10000) {
            let _room = res.data ?? {"roomName":""};
            let _id = _room.liveRoomId||''
            if (_id == '') {
              this.$message.error("直播间不存在！");
              setTimeout(() => {
                that.$router.push({
                  path: "/index",
                });
              }, 1000);
            } else {
              console.log("room",res.data)
              that.room = res.data;
              this.roomName = res.data.roomName;
              that.initTokenLiveRoom();
            }
          }
        });
        this.initLive("");
    },
    initTokenLiveRoom() {
      this.$api
        .selectMemberUserInfoByToken({
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.userId = res.data.userId;
            // this.room = res.data;
            this.isLogin = true;
            if (this.room.roomStatus == 0 || this.room.roomStatus == 1) {
              this.goLive();
            } else {
              //结束
              this.dialogVisible = true;
            }
          }
        });
    },
    outLiveJoinPerson() {
      this.$api
        .outLiveJoinPerson({
          liveRoomId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.room = res.data;
          }
        });
    },
    insertLiveJoinPerson() {
      this.$api
        .insertLiveJoinPerson({
          liveRoomId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.room = res.data;
            this.isJoin = true;
            this.isChat = false;
          }
        });
    },
    initLive(url) {
      console.log("live................");
      let _this = this;
      if (_this.playerLive != null) {
        _this.playerLive.dispose();
      }
      _this.playerLive = new Aliplayer(
        {
          id: "player-con",
          source: url,
          // rtsFallbackSource:
          //   "",
          width: "100%",
          height: "80vh",
          autoplay: true,
          isLive: true,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
          skinLayout: [
            {
              name: "bigPlayButton",
              align: "blabs",
              x: 30,
              y: 80,
            },
            {
              name: "infoDisplay",
            },
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                {
                  name: "liveDisplay",
                  align: "tlabs",
                  x: 15,
                  y: 6,
                },
                {
                  name: "fullScreenButton",
                  align: "tr",
                  x: 10,
                  y: 10,
                },
                {
                  name: "volume",
                  align: "tr",
                  x: 5,
                  y: 10,
                },
              ],
            },
          ],
        },
        function (player) {
          console.log("The player is created");
        }
      );
      
      _this.playerLive.on("liveStreamStop", () => {
        _this.dialogVisible = true;
        console.log("liveStreamStop")

      });
      //
    },
    checkLogin() {
      this.token = localStorage.getItem("pc_token") ?? "";
      if (this.isLogin) {
        return false;
      }
      if (this.token == "") {
        this.checkLoginTime();
      } else {
        if (this.checkLoginTimer != null) {
          clearInterval(this.checkLoginTimer);
          this.checkLoginTimer = null;
        }
      }
    },
    checkLoginTime() {
      let that = this;
      that.checkLoginTimer = setInterval(() => {
        that.token = localStorage.getItem("pc_token") ?? "";
        if (that.token != "") {
          if (that.checkLoginTimer != null) {
            clearInterval(that.checkLoginTimer);
            that.checkLoginTimer = null;
            that.isLogin = true;
          }
        }
      }, 1000);
    },
    goLive() {
      let that = this;
      request({
        method: "get",
        url: that.room.m3u8LiveAddr,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((res) => {
          that.initLive(that.room.m3u8LiveAddr);
          //加入直播间
          this.insertLiveJoinPerson()
          //开启聊天
          this.initWebSocket()
        })
        .catch((err) => {
          that.initLive("");
          //启用定时器
          that.initRady();
          // console.log(err);
        });
    },
    initRady() {
      let that = this;
      this.timer = setInterval(() => {
        request({
          method: "get",
          url: that.room.m3u8LiveAddr,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
          .then((res) => {
            clearInterval(this.timer);
            that.timer = null;
            that.initLive(that.room.m3u8LiveAddr);
            //加入直播间
            that.insertLiveJoinPerson()
            //开启聊天
            that.initWebSocket()
          })
          .catch((err) => {
            // console.log(err);
          });
      }, 1000);
    },
    initWebSocket() {
      let that = this;
      if (window.WebSocket) {
        let url = this.$http.wss + this.room.liveRoomId + "/"+this.userId;
        console.log("=====",url)
        this.ws = new WebSocket(url);
        this.ws.onopen = function (e) {
          console.log("连接服务器成功");

          if (that.wsTimer != null) {
            clearInterval(that.wsTimer);
            that.wsTimer = null;
          }
          that.wsTimer = setInterval(() => {
            if (that.ws.readyState != 1) {
              that.ws = null;
              that.initWebSocket();
            }
          }, 1000);
          // 向服务器发送消息
          // ws.send("来自客户端的消息");
        };
        this.ws.onclose = function (e) {
          console.log("服务器关闭");
        };
        this.ws.onerror = function () {
          console.log("连接出错");
        };
        // 接收服务器的消息
        this.ws.onmessage = function (e) {
          let message = e.data || "";
          console.log(message);
          if (message != "") {
            let chatInfo = JSON.parse(message);
            let target = chatInfo.target??''
            if(target == "all"){
              that.dialogVisible = true;
            }else{
              that.addChatInfo(chatInfo);
            }
            
          }
        };
      }
    },
    addChatInfo(chatInfo) {
      let chat = {
        liveChatId: chatInfo.liveChatId,
        userName: chatInfo.userName,
        chatContent: chatInfo.content,
        createTime: chatInfo.msgTime,
      };
      this.chatList.push(chat);
      this.jump(this.chatList.length - 1);
    },
    sendMessage() {
      let _sms = this.chatContent ?? "";
      if(this.isChat){
         this.$message.error("直播未开始或您还未登录，不能发送聊天内容！");
        return false;
      }
      if (_sms == "") {
        this.$message.warning("请输入聊天内容！");
        return false;
      }
      // this.chatList.push({ memberName: "游客", chatContent: this.sms });
      // document.getElementById('bottom').scrollIntoView()
      if (this.ws != null) {
        if (this.ws.readyState != 1) {
          this.ws = null;
          this.initWebSocket();
        }
      }
      this.updateLiveChat();
    },
    updateLiveChat() {
      this.$api
        .updateLiveChat({
          liveRoomId: this.$route.query.id,
          token: this.token,
          chatContent: this.chatContent,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.chatContent = "";
          }
        });
    },
    // 滚动触发按钮高亮
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".card");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100;
        if (judge) {
          this.activeStep = i;
          break;
        }
      }
    }, // 点击切换锚点
    jump(index) {
      let target = document.querySelector(".player-chat-content");
      let scrollItems = document.querySelectorAll(".card"); // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.activeStep = index;
      }
      let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector(".player-chat-content").scrollTop; // 滚动条距离滚动区域顶部的距离 // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口) // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次 // 计算每一小段的距离
      let step = total / 50;
      if (total > distance) {
        smoothDown(document.querySelector(".player-chat-content"));
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp(document.querySelector(".player-chat-content"));
      } // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < total) {
          distance += step;
          element.scrollTop = distance;
          setTimeout(smoothDown.bind(this, element), 1);
        } else {
          element.scrollTop = total;
        }
      } // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > total) {
          distance -= step;
          element.scrollTop = distance;
          setTimeout(smoothUp.bind(this, element), 1);
        } else {
          element.scrollTop = total;
        }
      }
    },
    handleClose() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.myplayer {
  width: 100%;
  height: 80vh;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}
#bottom {
  margin: 5px;
}
.player-div {
  width: 80%;
  height: 80vh;
  flex: 1;
  display: flex;
}
.player-chat {
  width: 25%;
  height: 100%;
  background-color: #fff;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.player-chat-content {
  height: 90%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.css_user_name {
  text-align: center;
  color: #4a4a4a;
  font-weight: bolder;
  /* font-size: 16px; */
}
.css_content {
  color: #505050;
  font-size: 12px;
  line-height: 0.24rem;
}

.css_ssss {
  z-index: 1000;
  flex-shrink: 0;
  height: 10%;
  // margin-top: 10px;
  // padding: 5px;
  // border-radius: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>